import { Resolver } from "react-hook-form";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgramPaymentType } from "../../../../types/dto/program";
import { programPaymenAcademy } from "../../../../shared/services";
import useProgramStudent from "../students/useProgramStudent";
import { message } from "antd";

export interface UseCreateProgramScheduleProps {
  programId: number;
  scheduleId: number;
  setOpenModal: (value: boolean) => void;
}

export default function useCreatePayment({ programId, scheduleId, setOpenModal }: UseCreateProgramScheduleProps) {
  const [loading, setLoading] = React.useState(false);
  const [userId, setUSerId] = React.useState<number>();
  const { getStudentsByUserIdAndProgramId } = useProgramStudent({ programId, user_id: userId });

  const { control, handleSubmit: onSubmit, reset } = useForm<ProgramPaymentType>({
    resolver: yupResolver(
      yup.object({
        user_id: yup.string().required('Campo requerido'),
        amount: yup.string().required('Campo requerido'),
        payment_date: yup.string().required('Campo requerido'),
      })
    ) as unknown as Resolver<ProgramPaymentType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;

    try {
      await getStudentsByUserIdAndProgramId().then(async (it)=> {
        
        if(it.length === 0){
          return message.error('Acción no permitida, primero debes inscribir al estudiante en el programa')
        }
        setLoading(true);
        setUSerId(+data.user_id)   
         await programPaymenAcademy.create({
          user_id: +data.user_id,
          program_payment_schedule_id: +scheduleId,
          program_id: +programId,
          program_student_id: it[0].id,
          payment_date: data.payment_date,
          amount: +data.amount,
        }).then(()=>setOpenModal(false))
        
        reset();
        
        message.success("Pago creado exitosamente");
      }).catch((err: any)=>{
        
        message.error(err.message);
      })
    } catch (err) {
      console.error("Error al crear el pago del programa:", err);
      message.error("Error al crear el pago");
    } finally {
      setLoading(false);
    }
  });

  return {
    control,
    loading,
    handleSubmit,
  };
}
