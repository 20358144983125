"use client";
import type { Resolver } from "react-hook-form";

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgramSchedulePaymentStudentType } from "../../../types/dto/scheduleStudent";
import {
  programPaymentScheduleAcademy,
  programPaymentScheduleStudentAcademy,
} from "../../../shared/services";
import { message } from "antd";

export interface UseCreateLevelProps {}

export default function useCreatePaymentScheduleStudent({}: UseCreateLevelProps) {
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit: onSubmit } = useForm<any>({
    resolver: yupResolver(
      yup.object({})
    ) as unknown as Resolver<ProgramSchedulePaymentStudentType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    try {
      await programPaymentScheduleStudentAcademy.create({
        program_student_id: +data.program_student_id,
        payment_schedule_id: +data.payment_schedule_id,
      });
      message.success("Se ha creado con éxito el cronograma del estudiante");
    } catch (err: any) {
      message.error("Algo salió mal: " + err.message);
    } finally {
      setLoading(false);
    }
  });

  const createScheduleStudent = async (data: {
    program_student_id: number;
    payment_schedule_id: number;
  }) => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await programPaymentScheduleStudentAcademy.create({
        program_student_id: +data.program_student_id,
        payment_schedule_id: +data.payment_schedule_id,
      });
      message.success("Se ha creado con éxito el cronograma del estudiante");
      return response;
    } catch (err: any) {
      message.error("Algo salió mal: " + err.message);
      throw err; // Lanzar el error para que pueda ser capturado en el bloque catch
    } finally {
      setLoading(false);
    }
  };

  const updateOrCreateScheduleStudent = async ({
    program_student_id,
    payment_schedule_id,
  }: {
    program_student_id: number;
    payment_schedule_id: number;
  }) => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await programPaymentScheduleStudentAcademy.find({
        query: {
          program_student_id: +program_student_id,
          payment_schedule_id: +payment_schedule_id,
        },
      });
      console.log("scheduleStudentAcademy", data);

      const record = await programPaymentScheduleStudentAcademy.patch(
        data[0].id,
        {},
        { query: { $client: { updateOrCreateScheduleStudent: true } } }
      );

      if (record.status === "active") {
        message.success(
          "Se ha asignado con éxito el cronograma de pago al estudiante"
        );
      } else {
        message.success(
          "Se ha removido con éxito el cronograma de pago al estudiante"
        );
      }
      return record; // Confirmación de éxito
    } catch (error: any) {
      message.error("Algo salió mal: " + error.message);
      return false; // Confirmación de fallo
    } finally {
      setLoading(false);
    }
  };

  return {
    control,
    loading,
    handleSubmit,
    createScheduleStudent,
    updateOrCreateScheduleStudent,
  };
}
