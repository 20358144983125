import { Button, Col, Typography } from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import React from "react";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { ProgramStudentType } from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table/prueba";
import { programsStudentAcademy } from "../../../../shared/services";
import useDeleteProgramStudents from "../../hooks/programStudent/useDeleteProgramStudents";
import { WrapperModalEditProgramStudent } from "../styled";
import EditProgramStudent from "./EditProgramStudent";

const { Title } = Typography;

interface TableProgramProps {
  studentId: number;
}

export const TableProgramPersonalInfo = ({ studentId }: TableProgramProps) => {
  

  const [programEstudentId, setProgramEstudentId] = React.useState<number>();
  const [openModalEditProgramStudent, setOpenModalEditProgramStudent] = React.useState<boolean>(false);

    const {showDeleteConfirm} = useDeleteProgramStudents()
    const handleOpenEditModal = (val: number) => {
      setProgramEstudentId(val)
      setOpenModalEditProgramStudent(true)
    }

  const columns: TableColumns<ProgramStudentType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Nombre del programa",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.program?.name}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Estado",
      width: 2,
      render: (_, item) => {
        const statusColors: Record<string, string> = {
          active: '#4bd542',
          inactive: 'red',
          pause: 'orange',
          completed: 'blue',
        };
        const statusText: Record<string, string> = {
          active: 'Activo',
          inactive: 'Inctivo',
          pause: 'Pausado',
          completed: 'Completado',
        };
        const bgColor = statusColors[item.status]
        const finalText = statusText[item.status]
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500", background:bgColor, padding: "5px 27px", borderRadius:20}} level={5}>
              {finalText}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      width: 1,
      fixed: "right",
      render: (_, item: ProgramStudentType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: 15,
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size="large"
              onClick={() => handleOpenEditModal(item.id,)}
            />
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
              onClick={() => showDeleteConfirm(item.id, item.program.name)}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
    <WrapperModalEditProgramStudent
        title={`Editar programa del estudiante`}
        open={openModalEditProgramStudent}
        width={"320px"}
        destroyOnClose
        onClose={() => setOpenModalEditProgramStudent(false)}
      >
        <EditProgramStudent
        programStudentId={+programEstudentId!}
          setOpenModal={setOpenModalEditProgramStudent}
        />
      </WrapperModalEditProgramStudent>
      <Table<ProgramStudentType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          user_id: studentId,
          $sort: { id: -1 },
        }}
        service={programsStudentAcademy}
      />
    </>
  );
};
