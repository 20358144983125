import { Controller, type Control } from "react-hook-form";
import {ProgramStudentModuleTechniqueType,} from "../../../../types/dto/program";
import { Select } from "antd";
 
export default function EditStudentQualificationForm({
  control,
}: {
  control?: Control<ProgramStudentModuleTechniqueType>;
}) {

  return (
    <div className="flex flex-col gap-3">
      <Controller
        control={control}
        name="qualification"
        render={({ field, fieldState }) => (
          <div>
            <label>Calificación del estudiente</label>
            <Select
              {...field}
              showSearch
              style={{ width: 270 }}
              placeholder="Calificación del estudiente"
              optionFilterProp="label"
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase());
              }}
              options={[
                {
                  value: "approved",
                  label: "Aprobado",
                },
                {
                  value: "failed",
                  label: "No aprobado",
                },
              ]}
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
    </div>
  );
}
