"use client";
import Button from "../../../../shared/components/ui/Button";
import { ProgramStudentModuleTechniqueType } from "../../../../types/dto/program";
import CreateStudentQualificationForm from "./CreateStudentQualificationForm";
import { useParams } from "react-router-dom";
import useProgramStudent from "../../../academyProgramDetail/hooks/students/useProgramStudent";
import React from "react";
import { Resolver, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useProgramModule from "../../hooks/useProgramsModule";
import useProgramTopic from "../../hooks/useProgramTopic";
import useProgramTechniques from "../../hooks/useProgramTechniques";
import useCreateQualificationStudent from "../../hooks/qualifications/useCreateQualificationStudent";

export default function CreateStudentQualification({
  setOpenModal,
}: {
  setOpenModal: (val: boolean) => void;
}) {
  const {
    control,
    handleSubmit: onSubmit,
    watch,
  } = useForm<ProgramStudentModuleTechniqueType>({
    resolver: yupResolver(
      yup.object({})
    ) as unknown as Resolver<ProgramStudentModuleTechniqueType>,
  });
  const program_id = watch("program_id");
  const module_id = watch("module_id");
  const topic_id = watch("topic_id");
  const technique_id = watch("technique_id");
  const { user_id } = useParams();

  const { programStudentByUserIdData, getProgramStudentsByUserId } =
    useProgramStudent({});
  const { programModuleByProgramId, getProgramModuleByProgramId } =
    useProgramModule();
  const { programModuleTopicByModuleId,  } = useProgramTopic(+module_id!);
  const { programTechniqueByTopicId } = useProgramTechniques(+topic_id!);

  React.useEffect(() => {
    getProgramStudentsByUserId(+user_id!);
  }, []);

  React.useEffect(() => {
    if (program_id) {
      getProgramModuleByProgramId(+program_id!);
    }
    // if (module_id) {
    //   getProgramModuleByProgramId(+program_id!);
    // }
  }, [program_id, module_id]);

  const { loading, handleSubmit } = useCreateQualificationStudent({
    onSubmit,
    userId: +user_id!,
    techniqueId: +technique_id!,
    setOpenModal
  });

  return (
    <form onSubmit={handleSubmit}>
      <CreateStudentQualificationForm
        control={control}
        programStudentByUserIdData={programStudentByUserIdData}
        programModuleByProgramId={programModuleByProgramId}
        programModuleTopicByModuleId={programModuleTopicByModuleId}
        programTechniqueByTopicId={programTechniqueByTopicId}
        userId={+user_id!}
        programId={+program_id!}
        moduleId={+module_id!}
        topicId={+topic_id!}
      />
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" type="submit" variant="filled">
          <i className="fa-regular fa-floppy-disk" /> Crear
        </Button>
      </div>
    </form>
  );
}
