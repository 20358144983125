import type { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import {
  Input,
  Select,
  TextArea,
} from "../../../../shared/components/ui/form/inputs";
import { ProgramType } from "../../../../types/dto/program";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { Col, message, Typography } from "antd";
import Upload from "../../../../shared/components/ui/inputs/Upload";
const { Title } = Typography;

export default function ProgramForm({
  control,
  setValue,
  img_path,
}: {
  control: Control<ProgramType>;
  watch: UseFormWatch<ProgramType>;
  setValue: UseFormSetValue<ProgramType>;
  img_path?: string;
}) {

  return (
    <div className="flex flex-col gap-3">
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Banner desktop:
        </Title>
        <img
          src={`${PATH_S3_BUCKET}/${img_path}`}
          alt="path_movile"
          style={{
            marginBottom: "7px",
            width: "100%",
            height: "100%",
            maxWidth: "250px",
            maxHeight: "250px",
          }}
        />
        <Upload
          filePath="bannerDesktop"
          onUploadedFile={(files) => {
            // console.log({ files });
            const file = files[0];
            setValue("image_path", file.fileKey);
            message.success("Se ha actualizado el path Desktop");
          }}
        />
      </Col>
      <Input
        style={{ background: "none" }}
        control={control}
        label="Nombre del programa"
        name="name"
        placeholder="Nombre del programa"
      />
      <TextArea
        style={{ background: "none" }}
        control={control}
        label="Descripción"
        name="description"
        placeholder="description"
      />
      <Input
        style={{ background: "none" }}
        control={control}
        label="Valor"
        name="price"
        placeholder="100000"
        type="number"
      />
      <Input
        style={{ background: "none" }}
        control={control}
        label="Posición"
        name="position"
        placeholder="100000"
        type="number"
      />
      <Select
        style={{ background: "none" }}
        control={control}
        label="Estado"
        name="status"
      >
        <option value="">Seleccionar...</option>
        <option value="active">Activo</option>
        <option value="inactive">Inactivo</option>
      </Select>
    </div>
  );
}
