import { AdminMenuLayout } from "../../../shared/components";
import { WrapperAcademyProgramsStudent } from "./styled";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { ModalPrograms } from "../../../shared/components/ui/modals/ModalPrograms";
import React from "react";
import { TableProgram } from "./programs/TableProgram";
const {Title} = Typography
export const AcademyPrograms = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <AdminMenuLayout
      pageDescription="ProductCategories"
      title="ProductCategories"
    >
      <div>
        <div>
          <WrapperAcademyProgramsStudent>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Title style={{ textAlign: "left", fontSize: 25 }}>
            Visualización de programas
          </Title>
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                size="large"
                onClick={() => setOpenModal(true)}
              >
                Agregar programa
              </Button>{" "}
            </div>
            <TableProgram />
          </WrapperAcademyProgramsStudent>
        </div>
      </div>
      <ModalPrograms openModal={openModal} setOpenModal={setOpenModal} />
    </AdminMenuLayout>
  );
};
