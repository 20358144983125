"use client";

import React from "react";
import { programPaymentScheduleStudentAcademy } from "../../../../shared/services";
import { ProgramSchedulePaymentStudentType } from "../../../../types/dto/scheduleStudent";

export default function useProgramStudentSchedule() {
  const [loading, setLoading] = React.useState(false);
  const [programStudentSchedule, setProgramStudentSchedule] = React.useState<ProgramSchedulePaymentStudentType[]>([]);

  const getProgramStudentSchedule = async (program_student_id: number) => {
    if (loading) return;
    setLoading(true);

    await programPaymentScheduleStudentAcademy
      .find({query: {program_student_id }})
      .then((response) => {
        console.log('response', response.data);
        
        setProgramStudentSchedule(response.data);
        })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    programStudentSchedule,
    getProgramStudentSchedule
  };
}
