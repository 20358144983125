import React from "react";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useUsers } from "../../users/hooks/useUsers";
import { CaUserAcademy } from "../../../types/dto/program";
import {
  WrapperAcademyProgramsStudent,
  WrapperModalPayment,
  WrapperModalQualificationStudent,
} from "./styled";
import { WrapperCardUserOrder } from "../../orders/ui/styled";
import { WrapperTabNav } from "../../products";
import { TableListProgramQualification } from "./qualifications/TableListProgramQualification";
import { Button, Typography } from "antd";
import { TableProgramPersonalInfo } from "./programStudent/TableProgramPersonalInfo";
import PayStudentProgramFromStudentView from "./programStudent/PayStudentProgramFromStudentView";
import CreateStudentQualification from "./qualifications/CreateStudentQualification";
import { TablePersonalInfoStudentPayment } from "./programStudent/TablePersonalInfoStudentPayment";
import { TablePaymentScheduleStudent } from "./qualifications/TablePaymentScheduleStudent";
import { ModalScheludePaymentStudent } from "../../../shared/components/ui/modals/ModalScheludePaymentStudent";

const { Title } = Typography;

export const AcademyProgramPersonalInfo = () => {
  const [studentInfo, setStudentInfo] = React.useState<CaUserAcademy>();
  const [openModalPaymentPtogram, setOpenModalPaymentPtogram] =
    React.useState<boolean>(false);
  const [openModalQualificationStudent, setOpenModalQualificationStudent] =
    React.useState<boolean>(false);
    const  [openModalScheduleStudent, setOpenModalScheduleStudent] = React.useState<boolean>(false)

  const { findUserById } = useUsers();
  const { user_id, program_id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fecth = async () => {
      await findUserById(+user_id!).then((it) => setStudentInfo(it));
    };
    fecth();
  }, [user_id]);
  const goBack = () => {
    navigate(`/gestion-academy/programs/details/${program_id}`)
  };

  return (
    <WrapperAcademyProgramsStudent>
      <div>
        <span
          className="flex items-center underline text-gray-500 cursor-pointer"
          onClick={goBack}
        >
          <ArrowLeftOutlined className="mr-2" />
          Ir atras
        </span>
        <Title style={{ textAlign: "left", fontSize: 30 }}>
            Informacion del estudiante
          </Title>
        <WrapperCardUserOrder>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <div>
              {" "}
              <label className="text-gray-500">Nombre:</label>
              <p>{studentInfo?.first_name}</p>
            </div>
            <div>
              {" "}
              <label className="text-gray-500">Apellido:</label>
              <p>{studentInfo?.last_name}</p>
            </div>
            <div>
              {" "}
              <label className="text-gray-500">Email:</label>
              <p>{studentInfo?.email}</p>
            </div>
            <div>
              {" "}
              <label className="text-gray-500">Telefono:</label>
              <p>{studentInfo?.phone}</p>
            </div>
          </div>
        </WrapperCardUserOrder>
      </div>
      <div>
        <div>
        <Title style={{ textAlign: "left", fontSize: 30, marginTop:10 }}>
            Programa</Title>
          <WrapperTabNav
            items={[
              {
                label: `PROGRAMAS DEL ESTUDIANTE`,
                key: "3",
                children: <TableProgramPersonalInfo studentId={+user_id!} />,
              },
              {
                label: `HISTORIAL DE CALIFICACIONES`,
                key: "5",
                children: (
                  <div>
                    <div className="text-right">
                      <Button
                        type="primary"
                        shape="round"
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => setOpenModalQualificationStudent(true)}
                      >
                        Calificar Estudiante
                      </Button>{" "}
                    </div>
                    <TableListProgramQualification />,
                  </div>
                ),
              },
              {
                label: `HISTORIAL DE PAGOS`,
                key: "4",
                children: (
                  <div>
                    <div className="text-right">
                      <Button
                        type="primary"
                        shape="round"
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => setOpenModalPaymentPtogram(true)}
                      >
                        Pagar programa
                      </Button>{" "}
                    </div>
                    <TablePersonalInfoStudentPayment />
                  </div>
                ),
              },
              {
                label: `CRONOGRAMA DE PAGOS`,
                key: "9",
                children: (
                  <>
                    <TablePaymentScheduleStudent />,
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
      <WrapperModalPayment
        title={`Asignar pago al estudiante `}
        open={openModalPaymentPtogram}
        width={"320px"}
        destroyOnClose
        onClose={() => setOpenModalPaymentPtogram(false)}
      >
        <PayStudentProgramFromStudentView
          setOpenModal={setOpenModalPaymentPtogram}
        />
      </WrapperModalPayment>
      <WrapperModalQualificationStudent
        title={`Calificar al estudiante`}
        open={openModalQualificationStudent}
        width={"320px"}
        destroyOnClose
        onClose={() => setOpenModalQualificationStudent(false)}
      >
        <CreateStudentQualification 
          setOpenModal={setOpenModalQualificationStudent}
        />
      </WrapperModalQualificationStudent>
    </WrapperAcademyProgramsStudent>
  );
};
