// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { createWalletMovements, walletMovementsChangeModalState, refreshTableWalletMovements, selectIsUpdateWalletMovements, selectWalletMovementsForUpdate, selectWalletMovementsRefreshTable, setIsWalletMovementsForUpdate, updateWalletMovements, selectArrUsersForWalletMovements } from "../../../redux";
import { DataWalletMovements } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";import { validateCreateWalletMovements, validateUpdateWalletMovements } from '../../../helpers';
import { usersService } from '../../../shared/services';
import { IUserResFind } from '../../../redux/@types/user';
import { getUsersForWalletMovements, getObjUsersForWalletMovements } from '../../../redux/actions/walletMovements.actions';

export const useWalletMovements = () => {
    const dispatch = useAppDispatch();

    const isUpdateWalletMovements = useSelector(
      selectIsUpdateWalletMovements
    );
    const dataUpdateWalletMovements = useSelector(
      selectWalletMovementsForUpdate
    );
    const isRefreshTableWalletMovements = useSelector(
      selectWalletMovementsRefreshTable
    );
    const arrUsers = useSelector(selectArrUsersForWalletMovements);

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const changeModalWalletMovementsState = () => {
      dispatch(setIsWalletMovementsForUpdate(false));
      dispatch(walletMovementsChangeModalState());
    };

    const formMethodsCreate = useForm<DataWalletMovements>({
       resolver: yupResolver(validateCreateWalletMovements),
    });

    const formMethodsUpdate = useForm<DataWalletMovements>({
       resolver: yupResolver(validateUpdateWalletMovements),
    });

    useEffect(() => {
      if (isUpdateWalletMovements) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateWalletMovements });
      }

      return () => {
        formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateWalletMovements, dataUpdateWalletMovements]);

    const onSubmitCreateOrUpdate = async (data: DataWalletMovements) => {
      console.log({ data });
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateWalletMovements) {
        //console.log("Actualiando===>", data);
        await updateWalletMovements({
          id: data.id,
          user_id: Number(data.user_id),
          type: "admin",
          amount_net: data.amount_net,
          description: data.description,
          expired_day: data.expired_day
        })
          .then(async(res: Partial<DataWalletMovements>) => {
            setIsLoading(false);
            // dispatch(mappingUpdatedUser(res))
            if (res.id) {
              dispatch(walletMovementsChangeModalState());
              message.success(
                `Se ha actualizado con éxito el movimiento con id:${res.id}`
              );
              dispatch(refreshTableWalletMovements(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      } else {
        // console.log('Creando===>',data)
        await createWalletMovements({
          ...data,
          user_id: Number(data.user_id),
          type: "admin",
        })
          .then((res: DataWalletMovements) => {
            setIsLoading(false);
            if (res.id) {
              dispatch(walletMovementsChangeModalState());
              message.success(
                `Se ha creado con éxito el movimiento con id:${res.id}`
              );
              dispatch(refreshTableWalletMovements(true));
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      }
    };

    useEffect(() => {
      if (isRefreshTableWalletMovements) {
        tableProps?.refresh();
        dispatch(refreshTableWalletMovements(false));
      }
    }, [isRefreshTableWalletMovements]);

    const servicesUsersForContactsDirectory = async () => {
      const objRes = {};
      await usersService.find({ query: {  $limit: 9999999999999  } }).then(({ data }: IUserResFind) => {
        dispatch(getUsersForWalletMovements(data));
        // console.log({data})
        data.forEach((item) => (objRes[`${item.id}`] = `${item.first_name} ${item.last_name}`));
        dispatch(getObjUsersForWalletMovements(objRes));
      });
    };

    useEffect(() => {
        servicesUsersForContactsDirectory();
    }, []);

  return {
    //state
    isUpdateWalletMovements,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalWalletMovementsState,
  }
}
