import CreateProgram from "../../../../modules/academyPrograms/ui/programs/createProgram";
import { WrapperModalUsers } from "./styled";


export const ModalPrograms = ({openModal, setOpenModal}: {openModal:boolean, setOpenModal: (value: boolean)=>void}) => {

  return (
    <WrapperModalUsers
      title={`Crear programa`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <CreateProgram setOpenModal={setOpenModal}/>
    </WrapperModalUsers>
  );
};
