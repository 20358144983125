import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { ContentLayout, WrapperLayout, WrapperSider } from "./styled";
import { NavBar } from "../ui";
import { useAdminMenuLayout } from "./hooks";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux";

const { Content } = Layout;
interface Props {
  children: ReactNode;
  title: string;
  pageDescription: string;
}

export const AdminMenuLayout: FC<Props> = ({
  children,
  title,
  pageDescription,
}) => {
  const user = useSelector(selectUser);
  const { collapsed, items,items2, pathname } = useAdminMenuLayout();
  return (
    <WrapperLayout>
      <NavBar />
      <ContentLayout>
        <WrapperSider
          theme="light"
          collapsedWidth="0"
          collapsible
          collapsed={collapsed}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className="logo" />
          <Menu selectedKeys={[pathname]} mode="inline" items={ user?.role !== "seller" ? items: items2} />
        </WrapperSider>
        <Content>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
              // height: "calc(100vh - 64px)",
              overflow: "auto",
            }}
          >
            {children}
          </div>
        </Content>
      </ContentLayout>
    </WrapperLayout>
  );
};
