import { useSelector } from "react-redux";
import { Col, Typography } from "antd";
import moment from "moment";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { DataWalletMovements } from "../../../redux/@types/walletMovements";
import { walletMovementsService } from "../../../shared/services";
import { useTableWalletMovements, useWalletMovements } from "../hooks";
import { decimal } from "../../../shared/utils/Helper";
import { selectObjUsersForWalletMovements } from "../../../redux";

const { Title } = Typography;

export const TableWalletMovements = () => {
  const objUsers = useSelector(selectObjUsersForWalletMovements);
  const { changeWalletMovementsForUpdate } = useTableWalletMovements();
  const { setTableProps } = useWalletMovements();
  const columns: TableColumns<DataWalletMovements> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Usuario destinatario",
      width: 7,
      dataIndex: "user_id",
      key: "user_id",
      render: (_, item) => {
        // console.log({ item });
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              {objUsers[`${item.user_id}`]}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Cantidad neta",
      width: 5,
      dataIndex: "amount_net",
      key: "amount_net",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.amount_net)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Descripción",
      width: 5,
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Dia de vencimiento",
      width: 7,
      dataIndex: "expired_day",
      key: "expired_day",
      render: (_, item) => {
        console.log({ item });
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {moment(item.expired_day).add("h", 5).format("DD/MM/YYYY")}
            </Title>
          </Col>
        );
      },
    },
    // {
    //   title: "Acciones",
    //   key: "operation",
    //   fixed: "right",
    //   width: 3,
    //   render: (_, item) => {
    //     return (
    //       <Col
    //         style={{
    //           display: "flex",
    //           width: "100%",
    //           justifyContent: "space-evenly",
    //           flexDirection: "row",
    //         }}
    //       >
    //         <Button
    //           type="primary"
    //           shape="circle"
    //           onClick={() => changeWalletMovementsForUpdate(item)}
    //           icon={<EditOutlined />}
    //           size="large"
    //         />
    //       </Col>
    //     );
    //   },
    // },
  ];

  return (
    <Table<DataWalletMovements>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={walletMovementsService}
      onLoad={setTableProps}
    />
  );
};
