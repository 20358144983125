"use client";
import type { Resolver } from "react-hook-form";

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { ProgramScheduleType } from "../../../../types/dto/program";
import { programPaymentScheduleAcademy } from "../../../../shared/services";
import { message } from "antd";

export interface UseCreateProgramScheduleProps {
  id: number;
  setOpenModal: (value: boolean) => void;
}

export default function useCreateProgramSchedule({
  id,
  setOpenModal,
}: UseCreateProgramScheduleProps) {
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit: onSubmit } = useForm<ProgramScheduleType>({
    resolver: yupResolver(
      yup.object({
        month: yup.string().required("Campo Requerido"),
        day_of_month_payment_limit: yup
          .string()
          .required("Campo Requerido"),
          price_with_prompt_payment_discount: yup
          .string()
          .required("Campo Requerido"),
      })
    ) as unknown as Resolver<ProgramScheduleType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    await programPaymentScheduleAcademy
      .create({
        ...data,
        program_id: +id,
        month: moment(data.month).toDate(),
        day_of_month_payment_limit: moment(data.day_of_month_payment_limit).toDate(),
        price_with_prompt_payment_discount: +data.price_with_prompt_payment_discount,
      })
      .then((response) => {
        message.success(
          `Se ha creado con éxito cronograma de pago:${response.id}`
        );
        setOpenModal(false);
      })
      .catch((err: Error) => {
        message.error(`Algo salio mal: ${err.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    control,
    loading,
    handleSubmit,
  };
}
