import { Controller, type Control } from "react-hook-form";
import { ProgramScheduleType } from "../../../../../types/dto/program";
import { DatePicker, Input } from "antd";

export default function ProgramScheduleForm({ control }: { control: Control<ProgramScheduleType> }) {
  return (
    <div className="flex flex-col gap-3">
      <div>
        <label>Selecciona mes de cobro</label>
        <Controller
          control={control}
          name="month"
          render={({ field, fieldState}) => (
            <div>
                <DatePicker style={{width: '100%'}} onChange={field.onChange} picker="month" />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
      <div>
        <label>Selecciona fecha limite de pago</label>
        <Controller
          control={control}
          name="day_of_month_payment_limit"
          render={({ field, fieldState}) => (
            <div>
                <DatePicker style={{width: '100%'}} onChange={field.onChange}  />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
      <div>
        <label>Valor a pagar</label>
        <Controller
          control={control}
          name="price_with_prompt_payment_discount"
          render={({ field, fieldState}) => (
            <div>
                <Input style={{width: '100%'}} onChange={field.onChange}  />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
    </div>
  );
}
