import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useWalletMovements,
  WrapperModalWalletMovements,
} from "../../../../modules/walletMovements";
import {
  selectIsUpdateWalletMovements,
  selectModalWalletMovements,
} from "../../../../redux";
import { DataWalletMovements } from "../../../../redux/@types";
import {
  selectWalletMovementsForUpdate,
  selectArrUsersForWalletMovements,
} from "../../../../redux/selectors/walletMovements.selectors";
import { InputData, InputNumber, InputSelect, InputText } from "../inputs";

const { Title } = Typography;

export const ModalWalletMovements = () => {
  const modalWalletMovements = useSelector(selectModalWalletMovements);
  const isUpdateWalletMovements = useSelector(selectIsUpdateWalletMovements);
  const updateWalletMovements = useSelector(selectWalletMovementsForUpdate);
  const arrUsers = useSelector(selectArrUsersForWalletMovements);

  const { isLoading, changeModalWalletMovementsState, onSubmitCreateOrUpdate } =
    useWalletMovements();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataWalletMovements>();
  return (
    <WrapperModalWalletMovements
      title={
        isUpdateWalletMovements
          ? `Actualizando movimiento: '${updateWalletMovements?.id!}'`
          : `Creando movimiento`
      }
      open={modalWalletMovements}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalWalletMovementsState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Usuario a realizar transacción:
        </Title>
        <InputSelect
          showSearch
          name="user_id"
          control={control}
          dataOptions={
            arrUsers?.map((item) => ({
              value: item.id.toString(),
              title: `${item.first_name} ${item.last_name}`,
            })) || []
          }
          label="Usuario"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Cantidad neta:
        </Title>
        <InputNumber
          placeHolder="3000"
          name="amount_net"
          control={control}
          min
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Descripción:
        </Title>
        <InputText
          placeHolder="Esta cantidad es mandada para compensar un pago..."
          name="description"
          control={control}
          rows
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Fecha de vencimiento:
        </Title>
        <InputData
          placeHolder="29/10/2022"
          name="expired_day"
          control={control}
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateWalletMovements
          ? "Actualizar información"
          : "Crear movimiento"}
      </Button>
    </WrapperModalWalletMovements>
  );
};
