import React from "react";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { WrapperAcademyProgramsDetail } from "./styled";
import { useNavigate, useParams } from "react-router-dom";
import { programsAcademy } from "../../../shared/services";
import { ProgramType } from "../../../types/dto/program";
import { WrapperTabNav } from "../../products";
import { Button, Typography } from "antd";
import { ModalProgramsSchedule } from "../../../shared/components";
import { TableProgramTeacher } from "./programTeacher/TableProgramTeacher";
import {
  LevelContext,
  LevelContextProvider,
} from "../../academyPrograms/context";
import ProgramModule from "./content/module/ProgramsModule";
import ProgramsTopics from "./content/topics/ProgramTopics";
import ProgramModuleTecnique from "./content/techniques/ProgramsTecnics";
import { TableProgramStudent } from "./programStudents/TableProgramStudent";
import { TableProgramPayments } from "./payment/TableProgramPayments";
import { ModalAssignedStudentProgram } from "../../../shared/components/ui/modals/ModalAssignedStudentProgram";
import { ModalAssignedTeacherProgram } from "../../../shared/components/ui/modals/ModalAssignedTeacherProgram";
import { TableHistoryPayment } from "./payment/TableHistoryPayment";
const {Title} = Typography
export const AcademyProgramDetail = () => {
  const [dataProgram, setDataProgram] = React.useState<ProgramType | undefined>(
    undefined
  );
  const [openModalAssignedStudentProgram, setOpenModalAssignedStudentProgram] =
    React.useState<boolean>(false);
  const [openModalAssignedTeacherProgram, setOpenModalAssignedTeacherProgram] =
    React.useState<boolean>(false);
  const [openModalProgramsSchedule, setOpenModalProgramsSchedule] =
    React.useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id) {
      programsAcademy.get(+id).then((it) => setDataProgram(it));
    }
  }, [id]);

  
  const goBack = () => {
    navigate("/gestion-academy/programs");
  };

  return (
    <LevelContextProvider>
      <LevelContext.Consumer>
        {({
          selectedProgramModuleId,
          selectedProgramModuleTopicId,
          selectedProgramModuleTechniqueId,
        }) => (
          <WrapperAcademyProgramsDetail>
            <span
              className="flex items-center underline text-gray-500 cursor-pointer"
              onClick={goBack}
            >
              <ArrowLeftOutlined className="mr-2" />
              Ir atras
            </span>
            <div>
              <Title style={{ textAlign: "left", fontSize: 25 }}>
                Programa: {dataProgram?.name}{" "}
              </Title>
              <h2 className="text-2xl"></h2>
              <WrapperTabNav
                items={[
                  {
                    label: `ESTUDIANTES`,
                    key: "3",
                    children: (
                      <>
                        <div className="text-right">
                          <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={() =>
                              setOpenModalAssignedStudentProgram(true)
                            }
                          >
                            Inscribir estudiante al programa
                          </Button>{" "}
                        </div>
                        <TableProgramStudent program_id={+id!} />
                      </>
                    ),
                  },
                  {
                    label: `PROFESORES`,
                    key: "4",
                    children: (
                      <>
                        <div className="text-right">
                          <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={() =>
                              setOpenModalAssignedTeacherProgram(true)
                            }
                          >
                            Inscribir profesor al programa
                          </Button>{" "}
                        </div>
                        <TableProgramTeacher program_id={+id!} />
                      </>
                    ),
                  },
                  {
                    label: `PLAN DE ESTUDIO`,
                    key: "1",
                    children: (
                      <div className=" flex flex-1 over">
                        <ProgramModule programId={+id!} />
                        <ProgramsTopics moduleId={selectedProgramModuleId} />
                        <ProgramModuleTecnique
                          programModuleTopicId={selectedProgramModuleTopicId}
                        />
                      </div>
                    ),
                  },
                  {
                    label: `CRONOGRAMA DE PAGOS`,
                    key: "2",
                    children: (
                      <>
                        <div className="text-right">
                          <Button
                            type="primary"
                            shape="round"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={() => setOpenModalProgramsSchedule(true)}
                          >
                            Agregar cronograma de pagos
                          </Button>{" "}
                        </div>
                        <TableProgramPayments program_id={+id!} />
                      </>
                    ),
                  },
                  {
                    label: `HISTORIAL DE PAGOS`,
                    key: "6",
                    children: <TableHistoryPayment program_id={+id!} />,
                  },
                ]}
              />
            </div>
            <ModalProgramsSchedule
              openModal={openModalProgramsSchedule}
              setOpenModal={setOpenModalProgramsSchedule}
            />
            <ModalAssignedStudentProgram
              openModal={openModalAssignedStudentProgram}
              setOpenModal={setOpenModalAssignedStudentProgram}
            />
            <ModalAssignedTeacherProgram
              openModal={openModalAssignedTeacherProgram}
              setOpenModal={setOpenModalAssignedTeacherProgram}
            />
          </WrapperAcademyProgramsDetail>
        )}
      </LevelContext.Consumer>
    </LevelContextProvider>
  );
};
