import { useParams } from "react-router-dom";
import Button from "../../../../shared/components/ui/Button";
import useCreatePayStudentProgramFromStudentView from "../../hooks/usePayStudentProgramFromStudentView";
import PayStudentProgramFromStudentViewForm from "./AssignedPayementToStudentForm";
import useProgramSchedule from "../../../academyProgramDetail/hooks/schedule/useProgramsSchedule";
import useProgramStudent from "../../../academyProgramDetail/hooks/students/useProgramStudent";
import React from "react";
import { userInfo } from "os";

export interface PayStudentProgramFromStudentViewProps {
  setOpenModal: (val: boolean) => void
}

export default function PayStudentProgramFromStudentView({setOpenModal}: PayStudentProgramFromStudentViewProps) {
  const { user_id, program_id } = useParams();

  const { programScheduleByProgram } = useProgramSchedule({programId: +program_id!});
  const { programStudentByUserIdData, getProgramStudentsByUserId } =
    useProgramStudent({});

  React.useEffect(() => {
    getProgramStudentsByUserId(+user_id!);
  }, []);

  const { control, loading, handleSubmit } =
  useCreatePayStudentProgramFromStudentView({ userId: +user_id!, setOpenModal});
  return (
    <form onSubmit={handleSubmit}>
      <PayStudentProgramFromStudentViewForm
        control={control}
        programScheduleByProgram={programScheduleByProgram}
        programStudentByUserIdData={programStudentByUserIdData}
        userId={+user_id!}
      />
      <div className="mt-2 flex items-center justify-end">
        <Button
          primary
          className="bg-[#072082]"
          loading={loading}
          type="submit"
          variant="filled"
        >
          <i className="fa-solid fa-save" /> Crear
        </Button>
      </div>
    </form>
  );
}
