import { Button, Col, Typography } from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { ProgramStudentType } from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table";
import { programsStudentAcademy } from "../../../../shared/services";
import useDeleteStudents from "../../hooks/students/useDeleteStudents";
import { useNavigate } from "react-router-dom";
import React from "react";
import { ModalEditStudentProgram } from "../../../../shared/components/ui/modals/ModalEditStudentProgram";

const { Title } = Typography;

interface TableProgramProps {
  program_id: number;
}

export const TableProgramStudent = ({ program_id }: TableProgramProps) => {
  const [showModalEditStudentProgram, setShowModalEditStudentProgram] = React.useState<boolean>(false)
  const [programStudentId, setProgramStudentId] = React.useState<number>()
  const { showDeleteConfirm } = useDeleteStudents();
  const navigate = useNavigate();

  const hanldeRedirectPersonalInfo = (user_id: number) => {
    navigate(`/gestion-academy/personal-info/${user_id}/${program_id}`);
  };

  const columns: TableColumns<ProgramStudentType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Usuario",
      width: 2,
      dataIndex: ['user', 'first_name'],
      filterType: "text",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${item?.user?.first_name} ${item?.user?.last_name}`}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Email",
      width: 2,
      dataIndex: ['user', 'email'],
      key: "email",
    },
    {
      title: "Telefono",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.user?.phone}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Estado",
      width: 2,
      render: (_, item) => {
        const statusColors: Record<string, string> = {
          active: '#4bd542',
          inactive: '#ff4d4f',
          pause: 'orange',
          completed: 'blue',
        };
        const statusText: Record<string, string> = {
          active: 'Activo',
          inactive: 'Inctivo',
          pause: 'Pausado',
          completed: 'Completado',
        };
        const bgColor = statusColors[item.status]
        const finalText = statusText[item.status]
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500", background:bgColor, padding: "5px 27px", borderRadius:20}} level={5}>
              {finalText}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      width: 2,
      fixed: "right",
      render: (_, item: ProgramStudentType) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
              gap: 15
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              size="large"
              onClick={() => hanldeRedirectPersonalInfo(item.user_id)}
            />
            <Button
              type="primary"
              shape="circle"
              danger
              onClick={() => {
                showDeleteConfirm(item.id, "");
              }}
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
    <ModalEditStudentProgram
    programStudentId={+programStudentId!}
    openModal={showModalEditStudentProgram}
    setOpenModal={setShowModalEditStudentProgram}
    />

      <Table<ProgramStudentType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          program_id,
          $sort: { id: -1 },
        }}
        service={programsStudentAcademy}
      />
    </>
  );
};
