import { Col, Checkbox, Button } from "antd";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { ProgramScheduleType } from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table/prueba";
import React from "react";
import { programPaymentScheduleAcademy } from "../../../../shared/services";
import { useParams } from "react-router-dom";
import useCreatePaymentScheduleStudent from "../../../AcademyStudentSchedulePayment/hooks/useCreatePaymentScheduleStudent";
import moment from "moment";
import useProgramStudent from "../../../academyProgramDetail/hooks/students/useProgramStudent";
import { formatNumberPrice } from "../../../../shared/utils/Helper";
import useProgramStudentSchedule from "../../hooks/programStudent/useProgramStudentSchedule";

export const TablePaymentScheduleStudent = () => {
  const { user_id, program_id } = useParams();

  const { updateOrCreateScheduleStudent } = useCreatePaymentScheduleStudent({});
  const { getProgramStudentSchedule, programStudentSchedule } =
    useProgramStudentSchedule();

  const { programStudentsByUserIdAndProgramId } = useProgramStudent({
    programId: +program_id!,
    user_id: +user_id!,
  });

  React.useEffect(() => {
    if (
      programStudentsByUserIdAndProgramId &&
      programStudentsByUserIdAndProgramId.length > 0
    ) {
      getProgramStudentSchedule(programStudentsByUserIdAndProgramId[0].id);
    }
  }, [programStudentsByUserIdAndProgramId]);


  const handleAssignSelected = async (id: number) => {
    await updateOrCreateScheduleStudent({
      payment_schedule_id: id,
      program_student_id: programStudentsByUserIdAndProgramId[0].id,
    });
    getProgramStudentSchedule(programStudentsByUserIdAndProgramId[0].id);
  };

  const columns: TableColumns<ProgramScheduleType> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 1,
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Mes de cobro",
      dataIndex: "month",
      key: "month",
      width: 3,
      render: (_, item: ProgramScheduleType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {moment(item.month).format("MMM / YYYY")}
            </span>
          </Col>
        );
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Fecha límite de pago",
      dataIndex: "day_of_month_payment_limit",
      key: "day_of_month_payment_limit",
      width: 3,
      render: (_, item: ProgramScheduleType) => (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <span className="capitalize">
            {moment(item.day_of_month_payment_limit).format("YYYY/MM/DD")}
          </span>
        </Col>
      ),
    },
    {
      title: "Monto a pagar",
      dataIndex: "day_of_month_payment_limit",
      key: "day_of_month_payment_limit",
      width: 3,
      render: (_, item: ProgramScheduleType) => (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <span className="capitalize">
            {formatNumberPrice(+item.price_with_prompt_payment_discount)}
          </span>
        </Col>
      ),
    },
    {
      title: "Asignar cronograma al estudiante",
      key: "operation",
      width: 6,
      fixed: "right",
      render: (_, item, i) => {
            
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: 15,
            }}
          >
            <Checkbox
              checked={!!programStudentSchedule.find(
                (schedule) =>
                  schedule.payment_schedule_id === item.id &&
                  schedule.status === "active")}
              onChange={(e) => handleAssignSelected(_.id)}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <Table<ProgramScheduleType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          month: {
            $gte: `${moment().format("YYYY")}-01-01`,
            $lt: `${moment().format("YYYY")}-12-31`,
          },
          $sort: { id: -1 },
        }}
        service={programPaymentScheduleAcademy}
      />
    </>
  );
};
