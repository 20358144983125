import React from "react";
import { programPaymenAcademy } from "../../../shared/services";
import { Resolver, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgramPaymentType } from "../../../types/dto/program";
import useProgramStudent from "../../academyProgramDetail/hooks/students/useProgramStudent";
import { message } from "antd";

export interface UseProgramStudentProps {
  userId: number;
  setOpenModal: (val: boolean) => void
}

export default function useCreatePayStudentProgramFromStudentView({
  userId,
  setOpenModal
}: UseProgramStudentProps) {
  const [loading, setLoading] = React.useState(false);
  const [programId, setProgramId] = React.useState<number>();
  const {programStudentsByUserIdAndProgramId } = useProgramStudent({ programId, user_id: userId});
  
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramPaymentType>({
    resolver: yupResolver(
      yup.object({})
    ) as unknown as Resolver<ProgramPaymentType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    setProgramId(+data.program_id!)
    await programPaymenAcademy
      .create({
        ...data,
        program_id: +data.program_id,
        amount: +data.amount,
        program_payment_schedule_id: +data.program_payment_schedule_id,
        user_id: userId,
        program_student_id: programStudentsByUserIdAndProgramId[0]?.id,
      })
      .then((res) => {
        message.success('Se ha creado con exito el pago del estudiante')
        setOpenModal(false)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    control,
    loading,
    handleSubmit,
  };
}
