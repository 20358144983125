import {  Col, Typography } from "antd";
import moment from "moment";
import "moment/locale/es";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import {
  ProgramPaymentType,
} from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table";
import {
  programPaymenAcademy,
} from "../../../../shared/services";
import { useParams } from "react-router-dom";
import { formatNumberPrice } from "../../../../shared/utils/Helper";
const { Title } = Typography;

interface TableProgramProps {
  program_id: number;
}

export const TablePaymentByStudent = () => {
  const {schedulei_d, program_id} = useParams()

  const columns: TableColumns<ProgramPaymentType> = [
    {
      title: "Fecha de pago",
      dataIndex: "id",
      key: "name",
      width: 2,
      render: (_, item: ProgramPaymentType) => {
        const date = moment(item.createdAt).format("MMMM/DD/YYYY");
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${date}`}
            </Title>
          </Col>
        );
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Fecha limite de pago",
      dataIndex: "id",
      key: "name",
      width: 2,
      render: (_, item: ProgramPaymentType) => {
        const monthName = moment()
          .month(+item.program_schedule.month - 1)
          .format("MMMM");

        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {moment(item.program_schedule.day_of_month_payment_limit).format(
                "YYYY/MM/DD"
              )}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Monto",
      key: "amount",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {formatNumberPrice(item.amount)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Estudiante",
      dataIndex: "id",
      key: "name",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${item?.user?.first_name} ${item?.user?.last_name}`}
            </Title>
          </Col>
        );
      },
    },
  ];

  return (
      <Table<ProgramPaymentType>
          columns={columns}
          scroll={{}}
          fetchQueryProps={{
            program_payment_schedule_id: schedulei_d,
            program_id,
          }}
          service={programPaymenAcademy}
        />
  );
};
